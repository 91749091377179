import React from 'react'
import './services.css'
import { BiCheck } from 'react-icons/bi'


const Services = () => {
  return (
    <section id='services'>
      <h5>What I Offer</h5>
      <h2>Services</h2>

      <div className="container services__container">
        <article className="service">
          <div className="service__head">
            <h3>Virtual Classes</h3>
          </div>

          <ul className='service__list'>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Live Virtual Classroom Instruction</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Personalized Online Tutoring Sessions</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Online Assessments and Feedback</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Curriculum Development and Consultation</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Interactive Educational Resources Sharing</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Customized Learning Plans for Individual Students</p>
            </li>
            
            
          </ul>
        </article>
        {/* end of ui/ux */}
        <article className="service">
          <div className="service__head">
            <h3>Life Skills</h3>
          </div>

          <ul className='service__list'>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Social Skills Development and Building Relationships</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Life Skills Workshops and Training Programs</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Emotional Intelligence Training and Self-Awareness</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Leadership Development and Empowerment Programs</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Character Education and Values Development</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Cultural Competence and Diversity Training Sessions</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Personal Development and Goal-Setting Guidance</p>
            </li>
            
            
          </ul>
        </article>
        {/* end of ui/ux */}
        <article className="service">
          <div className="service__head">
            <h3>In-Person Classes</h3>
          </div>

          <ul className='service__list'>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>In-Person Classroom Instruction</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Small Group Work and Collaboration</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Hands-on Experiments and Practical Activities</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Classroom Assessments and Progress Monitoring</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Individualized Student Support and Remediation</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Parent-Teacher Meetings and Consultations</p>
            </li>
            
            
          </ul>
        </article>
        {/* end of ui/ux */}
      </div>
    </section>
  )
}

export default Services
