import React from 'react'
import './testimonials.css'
import AVTR1 from '../../assets/avatar1.jpg'
import AVTR2 from '../../assets/avatar2.jpg'
import AVTR3 from '../../assets/avatar3.jpg'
import AVTR4 from '../../assets/avatar4.jpg'

import { Pagination } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';


const Testimonials = () => {
  return (
    <section id='testimonials'>
      <h5>Review from clients</h5>
      <h2>Testimonials</h2>

      <Swiper className="container testimonials__container"
      // install Swiper modules
      modules={[Pagination]}
      spaceBetween={40}
      slidesPerView={1}
      pagination={{ clickable: true }}
      >

        <SwiperSlide className="testimonial">
          <div className="client__avatar">
            <img src={AVTR1} alt="avatar one" />
          </div>
          <h5 className='client__name'>- Erne Achiever, <small>Colleague</small></h5>
          <small className="client__review">
          "Bishnu Pd. Dhakal is an exceptional educator who brings enthusiasm and expertise to the classroom. His dedication to his students' success is evident in his engaging teaching style and ability to connect with each student on a personal level. He goes above and beyond to ensure that every student feels supported and motivated to achieve their best."
          </small>
        </SwiperSlide>
        <SwiperSlide className="testimonial">
          <div className="client__avatar">
            <img src={AVTR2} alt="avatar one" />
          </div>
          <h5 className='client__name'>- John Smith, <small>Student</small></h5>
          <small className="client__review">
          "I am grateful to have had Bishnu Pd. Dhakal as my English teacher. His passion for the subject and his dynamic teaching methods made every class enjoyable and meaningful. He created a positive learning environment where I felt comfortable asking questions and exploring my creativity. His guidance and feedback have significantly improved my language skills and confidence."
          </small>
        </SwiperSlide>
        <SwiperSlide className="testimonial">
          <div className="client__avatar">
            <img src={AVTR3} alt="avatar one" />
          </div>
          <h5 className='client__name'>- Emily Thompson, <small>Colleague</small></h5>
          <small className="client__review">
          "Bishnu Pd. Dhakal's commitment to student growth is exceptional. As a colleague, I have witnessed his dedication to creating innovative lesson plans that cater to diverse learning styles. His ability to connect with students and foster a love for learning is truly inspiring. Bishnu is a valuable asset to any educational institution." 
          </small>
        </SwiperSlide>
        <SwiperSlide className="testimonial">
          <div className="client__avatar">
            <img src={AVTR4} alt="avatar one" />
          </div>
          <h5 className='client__name'>- Anna Davis, <small>Student</small></h5>
          <small className="client__review">
          "I cannot thank Bishnu Pd. Dhakal enough for his invaluable support and guidance. His personalized approach to teaching and his willingness to go the extra mile have had a profound impact on my educational journey. He continuously challenged me to reach my full potential and believed in my abilities. Bishnu's mentorship has shaped me into the confident learner I am today."
          </small>
        </SwiperSlide>
      </Swiper>

    </section>
  )
}

export default Testimonials
