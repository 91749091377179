import React from 'react'
import './about.css'
import ME from '../../assets/me-about.jpg'
import { FaAward } from 'react-icons/fa'
import { FiUsers } from 'react-icons/fi'
import { VscFolderLibrary } from 'react-icons/vsc'

const About = () => {
  return (
    <section id='about'>
      <h5>Get To Know</h5>
      <h2>About Me</h2>
      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="My self" />
          </div>

        </div>
        <div className="about__content">
          <div className="about__cards">

            <article className='about__card'>
              <FaAward className='about__icon'/>
              <h5>Experience</h5>
              <small>20+ Years Working</small>
            </article>

            <article className='about__card'>
              <FiUsers className='about__icon'/>
              <h5>Students</h5>
              <small>Educated 7000+ Students</small>
            </article>

            <article className='about__card'>
              <VscFolderLibrary className='about__icon'/>
              <h5>Qualification</h5>
              <small>Graduate from TU</small>
            </article>

          </div>
          <p>
          I am a graduate in English from Tribhuwan University, brings over 20+ years of teaching experience to the table. With a track record of working in multiple educational institutes, I have successfully educated over a thousand students. Hailing from Piluwa, Bara, Nepal, My dedication to shaping young minds have left a lasting impact on the community I serve.          </p>
          <a href="#contact" className='btn btn-primary'>Let's Talk</a>
        </div>
      </div>
    </section>
  )
}

export default About
